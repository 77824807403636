.gradient-custom {
  /* fallback for old browsers */
  background: #30cfd0;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to left, rgba(48,207,208,1), rgba(51,8,103,1));

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to left, rgba(48,207,208,1), rgba(51,8,103,1))
}

.gradient-pastel {

  /* fallback for old browsers */
  background: #1b85b8;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to left, rgba(26,133,184,1), rgba(29,8,103,1));

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to left, rgba(26,133,184,1), rgba(29,8,103,1));

  /* background: #357ADF; */

}

.gradient-new-theme {
  background: rgb(53,122,223);
  background: linear-gradient(90deg, rgba(53,122,223,1) 0%, rgba(53,122,223,1) 100%);

}

.button-comple2-background {

  background: #e3e7fa;
  color: #000000;
}

.pricing-background {
  background-color: #e3e7fa;
  /* color: #000000; */
}

.price {
  color: #357ADF;
}

.plan-features {
  border-color: #357ADF;
  border-bottom-style: solid;
  border-bottom-width:thin;
}

.pricing-margins {
  margin-top: 10vhpx;
  margin-bottom: 10vh;
  margin-left: 8vh;
  margin-right: 8vh;
  
}

.comple2-color {
  color: #df9b35;
}

.navlink-texts {
  font-size: medium;
  font-weight: 600
}

.landing-color-2 {
  background: rgba(27,75,140,1);
  background: #357ADF;
  color: #ffffff;
}

.landing-color-main {
  background: #030440;
}

.alt-light-background {
  background: #ffffff;
}

.contact-form {
  background: #ffffff;
  border-radius: 1vh;
  margin-left: auto;
  margin-right: auto;
  font-weight: 400;
  height: 60vh;
  max-height: 60vh;
  width: 100%;
}
.contact-form-elements {
  /* margin-left: 5vh;
  margin-right: 5vh; */
  width: 90%;
}

.footer-container {
  background-color: #01010f;
  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
}

.footer-container a {
  color: #ffffff;
}

/* Set the actual height relative of this value. */
.hero-container-height {
	height: 100vh;
}

.hero-height {
	height: 90vh;
	max-height:700px;
	vertical-align: middle;
}
.jumbotron {
  padding-top: 10vh;
  padding-bottom: 10vh;
  background-color: #e9ecef;
  border-radius: .3rem;
  height: 80vh;
  max-height: 700px;
  color: #ffffff;
}

.jumbotron-text {
  padding-top: 19vh;
  padding-bottom: 15vh;
}

.jumbotron-hero {
  padding-top: 0vh;
  padding-bottom: 10vh;
}

.minitron {
  padding-top: 10vh;
  padding-bottom: 10vh;
  border-radius: .3rem;
  height: 40vh;
  max-height: 40px;
  vertical-align: middle;
}

.minitron-2 {
  padding-top: 13vh;
  padding-bottom: 13vh;
  border-radius: .3rem;
  height: 60vh;
  max-height: 60px;
  vertical-align: middle;
}

.jumbotron-blog {
  padding-top: 0vh;
  padding-bottom: 0vh;
  background-color: #e9ecef;
  border-radius: .3rem;
  height: 45vh;
  max-height: 700px;
  color: #ffffff;
}

.minitron-blog {
  padding-top: 0vh;
  padding-bottom: 0vh;
  
  border-radius: .3rem;
  /*
  height: 30vh;
  max-height: 700px;
  */

}

.minitron-blog-header {
  padding-top: 10vh;
	/*
  padding-bottom: 10vh;
  */
}

.pre-format {
  display:block;
  padding:9.5px;
  font-size:87.5%;
  /* color:#212529; */
  color: white;
  background-color: black;
  overflow: auto;
  /* max-width: 830px; */
}

.new-line {
  white-space: pre-line;
}